import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export interface NotFoundPageProps {
  location: Location
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ location }) => {

  return (
    <Layout location={location} pageTitle="Page not found" canonicalUrl="https://liana.one/404" >
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

